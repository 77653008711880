import { useEffect, useMemo, useContext, useState } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { FiltersManagement } from '../filters-management/FiltersManagement';
import { EmptyPlaceholder, Preloader } from '../../../common';
import { roles, SORT } from '../../../../constants';
import { PipelineFilter } from '../Filter';
import { arrangerPipelineSelector } from '../../../../selectors/amr-pipeline.selector';
import { Table } from '../../../bidding/common/table';
import PipelineContext from '../PipelineContext';
import { amrArrangerPipelineActions } from '../../../../actions/amr-arranger-pipeline.actions';
import { createAmrPipelineActions, createSearchTransactionActions, imUserConfigActions } from '../../../../actions';
import { PipelineType } from '../../../../types/amr-pipeline/enums/PipelineType';
import { user } from '../../../../user';
import { IssuanceMonitorTab } from '../../types/PipelineTabTypes';
import { SyndicatePopup } from '../SyndicatePopup';
import { SyndicateContacts } from '../../../../types/amr-pipeline/models/SyndicateContacts';
import { UserConfigType } from '../../../../types/user-config/UserConfigType';
import { AppState } from '../../../../types/state/AppState';
import { ImSubscriptionActionBlocker } from '../../subscription/ImSubscriptionActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import classNames from 'classnames';
import { getArrangerPipelineColumns } from './arranger-pipeline.columns';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import { FiltersConfig } from '../../../../types/user-config/UserConfig';
import { RequiredFeature } from '../../../access/RequiredFeature';
import ArrangerPipelineSummary from './ArrangerPipelineSummary';
import { BlockedFeatureContent, SubscribeLink } from "../../../../components/access/BlockedFeatureText";
import { alertOptionTooltipText } from '../../../../constants/amr-pipeline/alertOptions';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export function ArrangerPipeline() {
    const dispatch = useAppDispatch();
    const { setTab, pipelineType } = useContext(PipelineContext);
    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const isMedia = user.hasRoles(roles.Media);

    const {
        deals: {
            arrangerPipelines,
            isLoading,
            sortBy,
            sortDirection,
            anyPipelineExists,
            hasMore,
            selectedDealsLegalNames,
            nonVisibleCount,
        },
        filter: { lastAppliedFilter, initialFilter, filterChanged, filterModified, selectedFilterReferenceName },
    } = useSelector(arrangerPipelineSelector);

    const filtersConfig = useSelector<AppState, FiltersConfig>(state => state.imUserConfig.filtersConfig);

    const [syndicateContacts, setSyndicateContacts] = useState<SyndicateContacts>();

    const isEditAvailable = user.hasRoles(...roles.admin(), ...roles.bd(), roles.DataEntry);
    const hasInvisibleDeals = !!(nonVisibleCount && nonVisibleCount > 0);
    const isBasicSeller = !withImSubscription && user.hasRoles(...roles.seller());

    const isFilterApplied = useMemo(
        () => !isEqual(lastAppliedFilter, initialFilter),
        [lastAppliedFilter, initialFilter],
    );

    useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig());
        dispatch(amrArrangerPipelineActions.checkIfArrangerPipelineExist());
        dispatch(amrArrangerPipelineActions.reset());
        dispatch(amrArrangerPipelineActions.initFilter());

        return () => {
            const actions = createAmrPipelineActions(pipelineType);
            const searchTransactionsActions = createSearchTransactionActions(pipelineType);

            dispatch(actions.reset());
            dispatch(searchTransactionsActions.reset());
        };
    }, [dispatch, pipelineType]);

    const onSort = (field: string) => {
        const switchSorting = (sort: string) => (sort === SORT.ASC ? SORT.DESC : SORT.ASC);

        dispatch(amrArrangerPipelineActions.sortingChange(field, switchSorting(sortDirection)));
        dispatch(amrArrangerPipelineActions.applyFilterAndSearch());
    };

    function loadArrangerPipelines() {
        if (!isLoading) dispatch(amrArrangerPipelineActions.arrangerPipelinesRequest());
    }

    const renderView = () => {
        if ((selectedDealsLegalNames.length || isFilterApplied) && !arrangerPipelines?.length) {
            return <EmptyPlaceholder />;
        }

        return (
            <Table
                onSort={onSort}
                infiniteScrollEnabled={hasMore}
                defaultSortBy={sortBy}
                defaultSortByDirection={sortDirection}
                onNextPageRequest={loadArrangerPipelines}
                className={classNames(
                    'data-list-striped data-list-arranger-pipeline',
                    { 'data-list-invisible-deals': isBasicSeller && hasInvisibleDeals })
                }
                dataItems={arrangerPipelines}
                isNextPageRequesting={isLoading}
                createSecurityCustomArgs={() => ({
                    dispatch,
                    setTab,
                    actions: amrArrangerPipelineActions,
                    setSyndicateContacts,
                    amrPipelineActions: createAmrPipelineActions(PipelineType.Deals),
                })}
                columns={getArrangerPipelineColumns(isMedia)}
                renderLastRow={(isBasicSeller && hasInvisibleDeals) ? () => (
                    <BlockedFeatureContent
                        inline
                        className="invisible-data-placeholder"
                        text={<><SubscribeLink /> to see {nonVisibleCount} more deal detail(s).</>}
                    />
                ) : null}
            />
        );
    };

    if (!initialFilter) {
        return <Preloader inProgress={true} />;
    }

    const renderAddPipeline = () => (
        <EmptyPlaceholder
            text={
                isEditAvailable
                    ? 'There is no created Arranger Pipeline yet. To create, push the button below, please.'
                    : 'There is no created Arranger Pipeline yet.'
            }
            className="arranger-pipeline-empty-placeholder"
        >
            {isEditAvailable && (
                <button className="btn btn-main" onClick={() => setTab && setTab(IssuanceMonitorTab.ArrangerPipelineEdit)}>
                    Add Pipeline
                </button>
            )}
        </EmptyPlaceholder>
    );

    const handleApplyFilter = () => dispatch(amrArrangerPipelineActions.applyFilterAndSearch());
    const handleResetFilter = () => dispatch(amrArrangerPipelineActions.resetFilter());

    const handleToggleFilterDefault = (referenceName: string, isDefault: boolean) => {
        dispatch(
            imUserConfigActions.setUserFilterParams(
                {
                    referenceName: referenceName,
                    default: isDefault,
                },
                UserConfigType.apFilter,
            ),
        );
    };

    const handleSaveFilterAlerts = (referenceName: string, alertOption: AlertOption, isDefault = false) => {
        dispatch(
            imUserConfigActions.setUserFilterParams(
                {
                    referenceName,
                    default: isDefault,
                    alertOption,
                },
                UserConfigType.apFilter,
            ),
        );
    };

    const handleDeleteFilter = (referenceName: string) =>
        dispatch(imUserConfigActions.deleteUserFilter(referenceName, UserConfigType.apFilter));


    const renderFiltersManagement = () => (
        <ImSubscriptionActionBlocker>
            {blocked => (
                <FiltersManagement
                    disabled={blocked}
                    onMakeDefault={handleToggleFilterDefault}
                    onResetDefault={handleToggleFilterDefault}
                    onSaveFilterAlert={handleSaveFilterAlerts}
                    onDeleteFilter={handleDeleteFilter}
                    filters={filtersConfig[UserConfigType.apFilter].value}
                    filterChanged={filterChanged}
                    filterModified={filterModified}
                    withAlerts={false}
                    filterType={PipelineType.ArrangerPipeline}
                    onChange={handleApplyFilter}
                    selectedFilterReferenceName={selectedFilterReferenceName}
                    alertOptionInfo={{
                        [AlertOption.Instant]: alertOptionTooltipText[AlertOption.Instant],
                        [AlertOption.Daily]: alertOptionTooltipText[AlertOption.Daily],
                    }}
                />
            )}
        </ImSubscriptionActionBlocker>
    );

    return (
        <>
            <div className="sub-header sub-header-pipeline sub-header-arranger-pipeline">
                <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                    <PipelineFilter
                        hiddenFilters={[]}
                        allowCollapsing={false}
                        isSearching={isLoading}
                        onApply={handleApplyFilter}
                        onReset={handleResetFilter}
                    />
                    <div className="flex-row flex-item-right save-manage-filters flex-none">
                        {renderFiltersManagement()}
                    </div>
                </div>
            </div>
            <div className="container-flex pipeline-table position-relative">
                {syndicateContacts && (
                    <SyndicatePopup
                        syndicateContacts={syndicateContacts}
                        onClose={() => setSyndicateContacts(undefined)}
                    />
                )}
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => (
                        <BlockedFeatureContent
                            inline
                            className="im-stats-placeholder"
                            text={<><SubscribeLink /> to see Arranger Pipeline stats.</>}
                        />
                    )}
                >
                    <ArrangerPipelineSummary />
                </RequiredFeature>
                <Preloader inProgress={isLoading && !arrangerPipelines?.length}>
                    {anyPipelineExists ? renderView() : renderAddPipeline()}
                </Preloader>
            </div>
        </>
    );
}
