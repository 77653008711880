import { isNil, startCase } from "lodash";
import cn from "classnames";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { constants, routes, pipelineColumnLabels, pipelineTooltipText, roles } from "../../../../../constants";
import { dateTimeUtils, formatUtils, moneyUtils } from "../../../../../utils";
import StatusLabel from "../../../StatusLabel";
import moment from "moment";
import { canViewTransactionDetails, getTransactionDetailsUrl, hasLimitedAccess } from "../../../../../utils/amr-pipeline.utils";
import { TransactionType } from "../../../../../types/amr-pipeline/enums/TransactionType";
import { OnHoverTooltip } from "../../../../common";
import { PipelineColumn } from '../../../types/PipelineColumn';
import { UserConfigColumn } from '../../../../../types/user-config/UserConfigColumn';
import { transactionStatusTitles } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { Transaction } from "../../../../../types/amr-pipeline/models/Transaction";
import { Popover } from "../../../../controls";
import { NewDebutAmrLabel } from "../../../common/NewDebutAmrLabel";
import { MarkdownViewer, renderMarkdownOrEmpty } from "../../../../common/markdown-editor";
import { amrFormatUtils } from "../../../../../utils/amr-format.utils";
import { Link } from "react-router-dom";
import IconSVG from "../../../../../styles/svg-icons";
import { DealUpdatesPopover } from "../../DealUpdatesPopover";
import { optionsButtonColumnDefinition, buildOptionsColumnDefinition, shareOptionColumnDefinition } from "./optionsColumn";
import { collapseStickRightColumn } from '../../../../bidding/common/table/CollapseStickRightColumn';
import { user } from "../../../../../user";
import { ImSubscriptionActionBlocker } from "../../../subscription/ImSubscriptionActionBlocker";
import { ManagerLink } from '../../../common/ManagerLink';
import { BankLink } from "../../../common/BankLink";

export const commonPipelineColumns = (columnsConfig: UserConfigColumn[]) => {
    const fixedColumnDefinitions: IColumnDefinition<
        OriginatingTransaction | AmrTransaction
    >[] = [
        {
            columnKey: PipelineColumn.isNew,
            renderColumnHeaderContent: () => "",
            renderColumnContent: (transaction: Transaction) =>
                <NewDebutAmrLabel transaction={transaction} />,
            className: "data-list-cell-xxs-02 data-list-cell-label",
        }
    ];

    const statsColumnDefinitions: IColumnDefinition<
        OriginatingTransaction | AmrTransaction
    >[] = [
        {
            columnKey: PipelineColumn.lastUpdated,
            renderColumnHeaderContent: () =>
            pipelineColumnLabels[PipelineColumn.lastUpdated],
            renderColumnContent: (transaction) => (
                <ImSubscriptionActionBlocker>
                    {blocked => {
                        const {
                            referenceName,
                            dealReferenceName,
                            dealLegalName,
                            lastUpdated,
                            type,
                            status,
                            version,
                        } = transaction;

                        return (
                            <DealUpdatesPopover
                                disabled={blocked}
                                referenceName={referenceName}
                                dealReferenceName={dealReferenceName}
                                dealLegalName={dealLegalName}
                                lastUpdated={lastUpdated}
                                type={type}
                                status={status}
                                version={version}
                                dealUpdates={(transaction as OriginatingTransaction).dealUpdates}
                            />
                        );
                    }}
                </ImSubscriptionActionBlocker>
            ),
            sortingField: PipelineColumn.lastUpdated,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: PipelineColumn.dealName,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.dealName],
            renderColumnContent: (transaction) => {
                const {
                    dealLegalName,
                    type
                } = transaction;

                return (
                    <div className="name-with-link">
                        <div className="text-ellipsis name-box">
                            <OnHoverTooltip overlay={dealLegalName}>
                                {type === TransactionType.amr || canViewTransactionDetails() ? (
                                    <Link
                                        target="_blank"
                                        to={getTransactionDetailsUrl(transaction)}
                                        className="link-filter"
                                    >
                                        {dealLegalName}
                                    </Link>
                                ) : (
                                    dealLegalName
                                )}
                            </OnHoverTooltip>
                        </div>
                    </div>
                );
            },
            sortingField: PipelineColumn.dealName,
            className: "data-list-cell-xl-xxl",
        },
        {
            columnKey: PipelineColumn.dealTicker,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.dealTicker],
            renderColumnContent: (transaction) => transaction.dealTicker || constants.emptyPlaceholder,
            sortingField: PipelineColumn.dealTicker,
            headerClassName: "data-list-cell-lg",
            bodyClassName: "data-list-cell-lg",
        },
        {
            columnKey: PipelineColumn.manager,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.manager],
            renderColumnContent: (transaction) => {
                const { collateralManager } = transaction;

                if (!collateralManager) { return constants.emptyPlaceholder}

                return (
                    <ManagerLink
                        referenceName={collateralManager.referenceName}
                        legalName={collateralManager.legalName}
                    />
                );
            },
            sortingField: "collateralManager",
            headerClassName: "data-list-cell-xl",
            bodyClassName: "data-list-cell-xl",
        },
        {
            columnKey: PipelineColumn.arranger,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.arranger],
            renderColumnContent: (transaction) => {
                const { arranger } = transaction;

                if (!arranger) {
                    return constants.emptyPlaceholder;
                }

                return (
                    <BankLink
                        legalName={arranger.code ?? arranger.legalName}
                        referenceName={arranger.referenceName}
                        overlayText={arranger.legalName}
                    />
                );
            },
            sortingField: PipelineColumn.arranger,
        },
        {
            columnKey: PipelineColumn.trustee,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trustee],
            renderColumnContent: (transaction) => transaction.trustee
                ? <OnHoverTooltip overlay={transaction.trustee?.legalName}>{transaction.trustee?.legalName}</OnHoverTooltip>
                : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-xl",
            bodyClassName: "data-list-cell-xl",
            sortingField: PipelineColumn.trustee,
        },
        {
            columnKey: PipelineColumn.transactionStatus,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.transactionStatus],
            renderColumnContent: (transaction) => (
                <StatusLabel status={transactionStatusTitles[transaction.status]} />
            ),
            sortingField: PipelineColumn.transactionStatus,
            headerClassName: "data-list-cell-trans-status",
            bodyClassName: "data-list-cell-trans-status",
        },
        {
            columnKey: PipelineColumn.transactionType,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.transactionType],
            renderColumnContent: (transaction) => startCase(transaction.type),
            sortingField: PipelineColumn.transactionType,
        },
        {
            columnKey: PipelineColumn.dealSize,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.dealSize],
            renderColumnContent: (transaction) =>
                transaction.dealBalance
                    ? moneyUtils.money(transaction.dealBalance)
                    : constants.emptyPlaceholder,
            sortingField: PipelineColumn.dealSize,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right",
        },
        {
            columnKey: PipelineColumn.pricingDate,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.pricingDate],
            renderColumnContent: (transaction) =>
                transaction.pricingDate
                    ? moment(transaction.pricingDate).format(
                          constants.dateFormat
                      )
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.pricingDate,
        },
        {
            columnKey: PipelineColumn.closingDate,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.closingDate],
            renderColumnContent: (transaction) =>
                transaction.closingDate
                    ? moment(transaction.closingDate).format(
                          constants.dateFormat
                      )
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.closingDate,
        },
        {
            columnKey: PipelineColumn.nonCallPeriodEnd,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEnd],
            renderColumnContent: ({ nonCallPeriodEnd }) =>
                nonCallPeriodEnd
                    ? moment(nonCallPeriodEnd).format(constants.dateFormatDoubleDay)
                    : constants.emptyPlaceholder,
            sortingField: PipelineColumn.nonCallPeriodEnd,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm popover-column",
        },
        {
            columnKey: PipelineColumn.nonCallPeriodEndDays,
            renderColumnHeaderContent: () =>
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.nonCallPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>,
            renderColumnContent: (transaction) =>
                dateTimeUtils.yrsLeftToDateFrom(
                    transaction.nonCallPeriodEnd,
                    transaction.closingDate,
                    false
                ) || constants.emptyPlaceholder,
            sortingField: PipelineColumn.nonCallPeriodEndDays,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right popover-column",
        },
        {
            columnKey: PipelineColumn.outOfNC,
            renderColumnHeaderContent: () =>
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
                    {pipelineColumnLabels[PipelineColumn.outOfNC]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>,
            renderColumnContent: ({ outOfNC }) =>
                isNil(outOfNC)
                    ? constants.emptyPlaceholder
                    : formatUtils.formatBoolean(outOfNC),
            sortingField: PipelineColumn.outOfNC,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm popover-column text-capitalize",
        },
        {
            columnKey: PipelineColumn.reinvestmentPeriodEnd,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEnd],
            renderColumnContent: ({ reinvestmentPeriodEnd }) =>
                reinvestmentPeriodEnd
                    ? moment(reinvestmentPeriodEnd).format(constants.dateFormatDoubleDay)
                    : constants.emptyPlaceholder,
            sortingField: PipelineColumn.reinvestmentPeriodEnd,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm popover-column",
        },
        {
            columnKey: PipelineColumn.reinvestmentPeriodEndDays,
            renderColumnHeaderContent: () =>
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.reinvestmentPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>,
            renderColumnContent: (transaction) =>
                dateTimeUtils.yrsLeftToDateFrom(
                    transaction.reinvestmentPeriodEnd,
                    transaction.closingDate,
                    false
                ) || constants.emptyPlaceholder,
            sortingField: PipelineColumn.reinvestmentPeriodEndDays,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right popover-column",
        },
        {
            columnKey: PipelineColumn.outOfRI,
            renderColumnHeaderContent: () =>
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
                    {pipelineColumnLabels[PipelineColumn.outOfRI]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>,
            renderColumnContent: ({ outOfRI }) =>
                isNil(outOfRI)
                    ? constants.emptyPlaceholder
                    : formatUtils.formatBoolean(outOfRI),
            sortingField: PipelineColumn.outOfRI,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm popover-column text-capitalize",
        },
        {
            columnKey: PipelineColumn.was,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.was],
            renderColumnContent: (transaction) =>
                formatUtils.formatDecimal(
                    (transaction as OriginatingTransaction).was
                ) || constants.emptyPlaceholder,
            headerClassName: "text-right",
            bodyClassName: "text-right",
            sortingField: PipelineColumn.was,
        },
        {
            columnKey: PipelineColumn.warf,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.warf],
            renderColumnContent: (transaction) => {
                const warf = (transaction as OriginatingTransaction).warf;
                return warf ? moneyUtils.money(warf, true) : constants.emptyPlaceholder;
            },
            headerClassName: "text-right",
            bodyClassName: "text-right",
            sortingField: PipelineColumn.warf,
        },
        {
            columnKey: PipelineColumn.ds,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.ds],
            renderColumnContent: (transaction) =>
                formatUtils.formatDecimal(
                    (transaction as OriginatingTransaction).ds
                ) || constants.emptyPlaceholder,
            headerClassName: "data-list-cell-xs text-right",
            bodyClassName: "data-list-cell-xs text-right",
            sortingField: PipelineColumn.ds,
        },
        {
            columnKey: PipelineColumn.euCompliance,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.euCompliance],
            renderColumnContent: (transaction) => {
                const {
                    dealLegalName,
                    euCompliance,
                    euRiskRetentionRequirements,
                    euDisclosureRequirements,
                } = transaction;
                const columnText =
                    formatUtils.formatBooleanWithPlaceholder(euCompliance);

                if (
                    euCompliance &&
                    (euRiskRetentionRequirements || euDisclosureRequirements)
                ) {
                    return (
                        <Popover
                            title="EU Compliance"
                            className="popover-eu-compliance"
                            label={
                                <span className="name-tag text-ellipsis">
                                    {dealLegalName}
                                </span>
                            }
                            actionButton={
                                <button className="pseudo-link">
                                    {columnText}
                                </button>
                            }
                        >
                            <h2 className="title-border">
                                EU Risk Retention Requirements
                            </h2>
                            <div className="popover-text-box">
                                {renderMarkdownOrEmpty(
                                    euRiskRetentionRequirements
                                )}
                            </div>
                            <h2 className="title-border">
                                EU Disclosure Requirements
                            </h2>
                            <div>
                                {renderMarkdownOrEmpty(
                                    euDisclosureRequirements
                                )}
                            </div>
                        </Popover>
                    );
                }

                return columnText;
            },
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.euCompliance,
        },
        {
            columnKey: PipelineColumn.esg,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.esg],
            renderColumnContent: (transaction) => {
                const { dealLegalName, esg, esgComment } = transaction;
                const columnText =
                    formatUtils.formatBooleanWithPlaceholder(esg);

                if (esg && esgComment) {
                    return (
                        <Popover
                            title="ESG Comment"
                            label={
                                <span className="name-tag text-ellipsis">
                                    {dealLegalName}
                                </span>
                            }
                            actionButton={
                                <button className="pseudo-link">
                                    {columnText}
                                </button>
                            }
                            className="popover-esg-comments"
                        >
                            <MarkdownViewer value={esgComment} />
                        </Popover>
                    );
                }

                return columnText;
            },
            headerClassName: "data-list-cell-xs",
            bodyClassName: "data-list-cell-xs",
            sortingField: PipelineColumn.esg,
        },
        {
            columnKey: PipelineColumn.collateralType,
            renderColumnHeaderContent: () => "Collat.",
            renderColumnContent: (transaction) =>
                formatUtils.formatCollateralType(
                    (transaction as OriginatingTransaction).collateralType
                ),
            headerClassName: "data-list-cell-xs",
            bodyClassName: "data-list-cell-xs",
            sortingField: PipelineColumn.collateralType,
        },
        {
            columnKey: PipelineColumn.currency,
            renderColumnHeaderContent: () => "Ccy",
            renderColumnContent: (transaction) =>
                (transaction as OriginatingTransaction).currency?.code ||
                constants.emptyPlaceholder,
            headerClassName: "data-list-cell-xs",
            bodyClassName: "data-list-cell-xs",
            sortingField: PipelineColumn.currency,
        },
        {
            columnKey: PipelineColumn.amrDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.amrDeal],
            renderColumnContent: (transaction) =>
                formatUtils.formatBooleanWithPlaceholder(
                    (transaction as OriginatingTransaction).amr
                ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.amr,
        },
        {
            columnKey: PipelineColumn.transactionSize,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.transactionSize],
            renderColumnContent: (transaction) =>
                moneyUtils.money(transaction.dealBalance) ||
                constants.emptyPlaceholder,
            headerClassName: "data-list-cell-md text-right",
            bodyClassName: "data-list-cell-md text-right",
            sortingField: "dealBalance",
        },
        {
            columnKey: PipelineColumn.offeringType,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.offeringType],
            renderColumnContent: (transaction) => (
                <>
                    {!!(transaction as OriginatingTransaction).offeringType ? (
                        <OnHoverTooltip
                            overlay={
                                (transaction as OriginatingTransaction)
                                    .offeringType
                            }
                        >
                            {
                                (transaction as OriginatingTransaction)
                                    .offeringType
                            }
                        </OnHoverTooltip>
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </>
            ),
            headerClassName: "data-list-cell-md",
            bodyClassName: "data-list-cell-md",
            sortingField: PipelineColumn.offeringType,
        },
        {
            columnKey: PipelineColumn.maturity,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.maturity],
            renderColumnContent: (transaction) => {
                const maturity =
                    (transaction as OriginatingTransaction).statedMaturity ||
                    (transaction as AmrTransaction).finalMaturity;
                return maturity
                    ? moment(maturity).format(constants.dateFormat)
                    : constants.emptyPlaceholder;
            },
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.maturity,
        },
        {
            columnKey: PipelineColumn.vintage,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.vintage],
            renderColumnContent: (transaction) => {
                const vintage =
                    (transaction as OriginatingTransaction).vintage
                return vintage
                    ? moment(vintage).year()
                    : constants.emptyPlaceholder;
            },
            sortingField: PipelineColumn.vintage,
        },
        {
            columnKey: PipelineColumn.firstPayment,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.firstPayment],
            renderColumnContent: (transaction) =>
                transaction.firstPaymentDate
                    ? moment(transaction.firstPaymentDate).format(
                          constants.dateFormat
                      )
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.firstPayment,
        },
        {
            columnKey: PipelineColumn.intex,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.intex],
            renderColumnContent: (transaction) => {
                const { intexName, intexPassword } =
                    transaction as OriginatingTransaction;
                return !intexName && !intexPassword ? (
                    constants.emptyPlaceholder
                ) : (
                    <OnHoverTooltip
                        overlay={
                            (intexName || constants.emptyPlaceholder) +
                            " / " +
                            (intexPassword || constants.emptyPlaceholder)
                        }
                    >
                        {(intexName || constants.emptyPlaceholder) +
                            " / " +
                            (intexPassword || constants.emptyPlaceholder)}
                    </OnHoverTooltip>
                );
            },
            headerClassName: "data-list-cell-xl",
            bodyClassName: "data-list-cell-xl",
        },
        {
            columnKey: PipelineColumn.numberOfDocuments,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.numberOfDocuments],
            renderColumnContent: (transaction, { userCompany }) => {
                const isMedia = user.hasRoles(roles.Media);

                if (!transaction.numberOfDocuments) {
                    return constants.emptyPlaceholder;
                }

                return !isMedia && (transaction.type === TransactionType.amr || (canViewTransactionDetails() && !hasLimitedAccess(transaction, userCompany))) ? (
                    <a
                        className="text-ellipsis"
                        href={routes.transactionDetailUrl(
                            transaction.referenceName,
                            transaction.dealReferenceName,
                            "documents"
                        )}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        View ({transaction.numberOfDocuments})
                    </a>
                ) : (
                    <span className="text-ellipsis">
                        {transaction.numberOfDocuments}
                    </span>
                );
            },
            sortingField: PipelineColumn.numberOfDocuments,
        },
        {
            columnKey: PipelineColumn.syndicate,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.syndicate],
            renderColumnContent: (transaction, context) => {
                const { dispatch, actions } = context;
                const numberOfContacts = (transaction as OriginatingTransaction)
                    .numberOfSyndicateContacts;
                return numberOfContacts ? (
                    <span
                        className="text-ellipsis pseudo-link"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                                actions.setSyndicateContacts(
                                    transaction.dealLegalName,
                                    transaction.dealReferenceName,
                                    transaction.referenceName,
                                    transaction.arranger?.legalName
                                )
                            );
                        }}
                    >
                        {numberOfContacts} contact
                        {numberOfContacts > 1 ? "s" : ""}
                    </span>
                ) : (
                    constants.emptyPlaceholder
                );
            },
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.syndicate,
        },
        {
            columnKey: PipelineColumn.enhancedCLO,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.enhancedCLO],
            renderColumnContent: (transaction) =>
                formatUtils.formatBooleanWithPlaceholder(
                    transaction.enhancedCLO
                ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.enhancedCLO,
        },
        {
            columnKey: PipelineColumn.staticDeal,
            renderColumnHeaderContent: () =>
                pipelineColumnLabels[PipelineColumn.staticDeal],
            renderColumnContent: (transaction) =>
                formatUtils.formatBooleanWithPlaceholder(
                    transaction.staticDeal
                ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
            sortingField: PipelineColumn.staticDeal,
        },
        {
            columnKey: PipelineColumn.rollerDeadline,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.rollerDeadline],
            renderColumnContent: (transaction) => {
                const { rollerDeadline } = transaction as OriginatingTransaction;
                return rollerDeadline
                    ? <div className={cn({ today: dateTimeUtils.isToday(rollerDeadline) })}>
                        {amrFormatUtils.formatRollerDeadlineDate(moment(rollerDeadline))}
                    </div>
                    : constants.emptyPlaceholder;
            },
            headerClassName: "data-list-cell-xl",
            bodyClassName: "data-list-cell-xl",
            sortingField: PipelineColumn.rollerDeadline,
        },
        {
            columnKey: PipelineColumn.iois,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.iois],
            renderColumnContent: (transaction) =>
                formatUtils.formatBooleanWithPlaceholder(
                    transaction.type !== TransactionType.amr &&
                    (transaction as OriginatingTransaction).classes.some(c => c.numberOfIOIs)
                ),
            headerClassName: "data-list-cell-xs",
            bodyClassName: "data-list-cell-xs",
        },
        {
            columnKey: PipelineColumn.options,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.options],
            renderColumnContent: () => ""
        },
    ];

    const customizedColumns = columnsConfig
        .reduce((acc: ColumnBuilder<OriginatingTransaction | AmrTransaction>[], columnConfig: UserConfigColumn) => {
            const column = statsColumnDefinitions.find(
                ({ columnKey }) => columnKey.toLocaleLowerCase() === columnConfig.name.toLocaleLowerCase()
            );

            if (!column) {
                return acc;
            }

            if (column.columnKey === PipelineColumn.options) {
                const isShareAvailable = user.hasRoles(...roles.bd());
                const isButtonAvailable = user.hasRoles(
                    ...roles.getAllRolesExclude(
                        ...roles.admin(),
                        roles.DataEntry,
                        roles.Viewer,
                        roles.Media,
                    )
                );
                const isBdOrSeller = user.hasRoles(roles.SellerTrader, roles.BrokerDealerTrader);

                return [
                    ...acc,
                    new ColumnBuilder(collapseStickRightColumn),
                    ...(isButtonAvailable
                        ? [new ColumnBuilder(optionsButtonColumnDefinition)]
                        : []),
                    ...(isShareAvailable
                        ? [new ColumnBuilder(shareOptionColumnDefinition)]
                        : []),
                    new ColumnBuilder(buildOptionsColumnDefinition(isBdOrSeller)),
                ];
            }

            return [...acc, new ColumnBuilder(column)];
        }, []);

    return fixedColumnDefinitions
        .map(column => new ColumnBuilder(column))
        .concat(customizedColumns);
};
